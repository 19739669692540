import { styled } from '@mui/material/styles';

export const LogoWrapper = styled('div')(() => ({
  cursor: 'pointer',
  display: 'flex',
  alignItems: 'center',
}));

export const LogoImage = styled('img')(() => ({
  height: '45px',
  width: '52px',
}));

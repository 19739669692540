import axios, { AxiosError, AxiosResponse, InternalAxiosRequestConfig } from 'axios';
import { onAuthStateChanged } from 'firebase/auth';

import { auth } from 'configuration/firebase';
import { getBaseUrl, getClientId, getUserAgent } from 'utils/api';
import { getApiError } from 'utils/error';

axios.defaults.baseURL = getBaseUrl();

const getUserToken = async () => new Promise((resolve) => {
  const unsubscribe = onAuthStateChanged(auth, async (userResponse) => {
    if (userResponse) {
      const token = await userResponse.getIdToken();
      resolve(token);
    } else {
      resolve(null);
    }

    unsubscribe();
  });
});

const onRequest = async (config: InternalAxiosRequestConfig): Promise<InternalAxiosRequestConfig> => {
  const token = await getUserToken();

  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }

  config.headers['Content-Type'] = 'application/json';
  config.headers['X-edge-client-id'] = getClientId();
  config.headers['X-edge-agent'] = getUserAgent();

  return config;
};

const onResponse = (response: AxiosResponse): AxiosResponse => response;

const onResponseError = (error: AxiosError): Promise<AxiosError> => Promise.reject(getApiError(error));

const setupAxiosInterceptors = () => {
  axios.interceptors.request.use(onRequest);
  axios.interceptors.response.use(onResponse, onResponseError);
};

export default setupAxiosInterceptors;

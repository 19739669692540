import { styled } from '@mui/material/styles';

export const Root = styled('div')(() => ({
  height: '100%',
}));

export const Skeleton = styled('div')(() => ({
  height: 'inherit',
  width: '100%',
  backgroundColor: 'rgb(247 243 243 / 14%)',
  borderRadius: '12px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
}));

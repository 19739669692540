/* eslint max-len: 0 */
import { ReactElement } from 'react';

import { CustomIcon } from 'models/icon.interface';

const Download = ({ color = 'current', size = 24 }: CustomIcon): ReactElement => (
  <svg xmlns="http://www.w3.org/2000/svg" height={size} viewBox="0 0 24 24" width={size} fill={color}>
    <path d="M0 0h24v24H0V0z" fill="none" />
    <path d="M13 5v6h1.17L12 13.17 9.83 11H11V5h2m2-2H9v6H5l7 7 7-7h-4V3zm4 15H5v2h14v-2z" />
  </svg>
);

export default Download;

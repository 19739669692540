import { format } from 'date-fns';

import { DateFormats } from 'constants/enums';

export const formatDate = (date: number, dateFormat = DateFormats.defaultDate) => format(new Date(date), dateFormat);

export const formatRoute = (route: string, params: Record<string, any>): string => {
  let formattedRoute = route;

  Object.keys(params).forEach((key) => {
    formattedRoute = formattedRoute.replace(`:${key}`, params[key]);
  });

  return formattedRoute;
};

export const formatPercentage = (percentage: number) => {
  const floatPercentage = Number.parseFloat(percentage.toString()) * 100;
  return Math.floor(floatPercentage);
};

import { useEffect, useState } from 'react';

import { yContentPadding } from 'assets/theme/customSpacing';

const aspectRatio = 0.56; // 9:16

const useStoryDimensions = () => {
  const [dimensions, setDimensions] = useState({ height: 0, width: 0, isPortrait: false });

  useEffect(() => {
    const resizeObserver = new ResizeObserver((entries) => {
      const bodyHeight = entries[0]?.contentRect?.height || 0;
      const bodyWidth = entries[0]?.contentRect?.width || 0;

      // If height is greater than width, the device is in portrait mode
      const isPortrait = bodyHeight > bodyWidth;

      setDimensions({
        width: isPortrait ? bodyWidth : bodyHeight * aspectRatio,
        height: bodyHeight - (2 * yContentPadding),
        isPortrait,
      });
    });

    if (document.getElementsByTagName('body')) {
      resizeObserver.observe(document.body);
    }
  }, []);

  return dimensions;
};

export default useStoryDimensions;

import campaignsConfiguration from '../../flavours/campaigns/config';

const FLAVOUR = process.env.REACT_APP_FLAVOUR || 'primaryacct';
const TARGET_ENV = process.env.REACT_APP_TARGET_ENV || 'prod';

// eslint-disable-next-line
console.log(`loading ${FLAVOUR}/${TARGET_ENV} in ${process.env.NODE_ENV} mode`);

const {
  flavourConfig,
  prodConfig,
} = campaignsConfiguration;

export default {
  ...flavourConfig,
  ...prodConfig,
};

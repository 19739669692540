import { Outlet, useNavigate, useSearchParams } from 'react-router-dom';

import Logo from 'components/Logo';
import {
  IconButton, Root, HeaderWrapper, Container,
} from 'layouts/CampaignViewerLayout/index.styled';
import CustomSuspense from 'routes/CustomSuspense';

import useStoryDimensions from 'hooks/useStoryDimensions';

import CancelCircleFilled from 'assets/icons/CancelCircleFilled';
import theme from 'assets/theme/theme';
import { getBackgroundColor } from 'utils/theme';

const CampaignViewerLayout = () => {
  const navigate = useNavigate();
  const { width, height } = useStoryDimensions();
  const [searchParams] = useSearchParams();
  const embedded = searchParams?.get('embedded') || '';
  const backgroundColor = getBackgroundColor(searchParams);

  const hasBackOption = window.history.length > 1;

  const handleBack = () => {
    navigate(-1);
  };

  return (
    <Root backgroundColor={backgroundColor}>
      {!embedded && (
        <HeaderWrapper>
          <Logo />
          {hasBackOption && (
            <IconButton onClick={handleBack}>
              <CancelCircleFilled color={theme.palette.general.lightGrey3} size={34} />
            </IconButton>
          )}
        </HeaderWrapper>

      )}
      <Container style={{ width, height }}>
        <CustomSuspense>
          <Outlet />
        </CustomSuspense>
      </Container>
    </Root>
  );
};

export default CampaignViewerLayout;

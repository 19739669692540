import {
  AccessMode,
  BountyType,
  AllowMode,
  ProductFlavours,
} from 'constants/enums';
import * as Operations from 'constants/op';
import { identityMode } from 'utils/identityMode';

export function getAllowed(op: string) {
  switch (op) {
    case Operations.SEE_ADDRESSES.name:
    case Operations.SEE_MARKET.name:
    case Operations.SEE_BUY_SELL.name:
    case Operations.SEE_LIKE.name:
      return AllowMode.May;
    case Operations.SEE_FAVORITES.name:
    case Operations.SEE_LEADERBOARD.name:
      return AllowMode.Should;
    default:
      return AllowMode.Default;
    }
}

export const ProductConfig = {
  identityMode: identityMode.ANON,
  isSocialPostEnabled: true,
  accessMode: AccessMode.PIN,
  hideBulletins: false,
  isFollowUserEnabled: true,
  bountyTypes: [
    BountyType.TMOB_Coaching,
    BountyType.TMOB_Challenge,
    BountyType.Survey,
    BountyType.Classified,
  ],
  getAllowed: (op: string) => getAllowed(op),
};

function BackstageProductConfig() {
  return {
    ...ProductConfig,
    isFollowUserEnabled: false,
  };
}

function RebountyProductConfig() {
  return {
    ...ProductConfig,
    getAllowed: (op: string) => {
      switch (op) {
        case Operations.SEE_ADDRESSES.name:
        case Operations.SEE_MARKET.name:
        case Operations.SEE_BUY_SELL.name:
        case Operations.SEE_LIKE.name:
        case Operations.SEE_FAVORITES.name:
        case Operations.SEE_LEADERBOARD.name:
          return AllowMode.May;
        default:
          return AllowMode.Default;
      }
    },
  };
}

function StashProductConfig() {
  return {
    ...ProductConfig,
    getAllowed: (op: string) => {
      switch (op) {
        case Operations.SEE_DASHBOARD.name:
        case Operations.SEE_ADDRESSES.name:
        case Operations.SEE_MARKET.name:
        case Operations.SEE_BUY_SELL.name:
        case Operations.SEE_LIKE.name:
          return AllowMode.Should;
        case Operations.SEE_FAVORITES.name:
          return AllowMode.May;
        default:
          return AllowMode.Default;
      }
    },
    bountyTypes: [
      BountyType.Classified,
      BountyType.Product,
    ],
  };
}

export function getInstance(productFlavour: ProductFlavours) {
  switch (productFlavour) {
    case ProductFlavours.Rebounty:
      return RebountyProductConfig();
    case ProductFlavours.Backstage:
      return BackstageProductConfig();
    case ProductFlavours.Stash:
      return StashProductConfig();
    case ProductFlavours.Alleaves:
      return {
        ...ProductConfig,
        isFollowUserEnabled: false,
      };
    default:
      return ProductConfig;
  }
}

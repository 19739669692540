import MuiContainer from '@mui/material/Container';
import { styled } from '@mui/material/styles';

import { xContentPadding } from 'assets/theme/customSpacing';
import palette from 'assets/theme/palette';

export const Root = styled('div')(({ theme }) => ({
  backgroundColor: palette.background.main,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  position: 'relative',

  '& .appLogo': {
    position: 'absolute',
    left: '30px',

    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  maxHeight: '100vh',
  overflow: 'hidden',
  color: theme.palette.common.white,
  padding: theme.spacing(1, 0),
}));

export const Container = styled(MuiContainer)(() => ({
  '&.MuiContainer-root': {
    paddingLeft: `${xContentPadding}px`,
    paddingRight: `${xContentPadding}px`,
    height: '-webkit-fill-available',
  },
}));

/* eslint max-len: 0 */
import { ReactElement } from 'react';

const Apple = (): ReactElement => (
  <svg xmlns="http://www.w3.org/2000/svg" width="22" height="26.957" data-name="&lt;Group&gt;" viewBox="0 0 26 31.957">
    <path
      fill="#fff"
      d="M21.715 17.75a6.832 6.832 0 0 1 3.459-5.79 7.546 7.546 0 0 0-5.857-3c-2.464-.246-4.853 1.4-6.109 1.4-1.28 0-3.214-1.376-5.3-1.335a7.863 7.863 0 0 0-6.564 3.8c-2.838 4.666-.721 11.523 2 15.295 1.36 1.847 2.95 3.91 5.03 3.837 2.036-.08 2.8-1.232 5.253-1.232 2.434 0 3.148 1.232 5.27 1.186 2.184-.034 3.56-1.855 4.873-3.719a14.973 14.973 0 0 0 2.228-4.309 6.667 6.667 0 0 1-4.283-6.133ZM17.79 5.937a8.286 8.286 0 0 0 1.9-5.936 8.431 8.431 0 0 0-5.455 2.822 7.885 7.885 0 0 0-1.945 5.716 6.971 6.971 0 0 0 5.5-2.602Z"
      data-name="&lt;Path&gt;"
    />
  </svg>
);

export default Apple;

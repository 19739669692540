export enum QueueActions {
  UpdateUser = 'user_update',
  UserAction = 'user_action',
  NewCreditCard = 'user_credit_card_new',
  FollowUser = 'user_follow',
  AddComment = 'comment_add',
  EditComment = 'comment_edit',
  DeleteComment = 'comment_del',
  LikeBounty = 'bounty_like',
  ReportBounty = 'bounty_report',
  RejectBounty = 'bounty_reject',
  ReportResponse = 'response_report',
  RejectResponse = 'response_reject',
  LikeResponse = 'response_like',
  DeleteResponse = 'response_delete',
  PublishResponse = 'response_publish',
  UpdateResponse = 'response_update',
  AcceptResponse = 'response_accept',
  GetList = 'list_get',
  DeleteAttachment = 'attachment_delete',
}

/* eslint max-len: 0 */
import { ReactElement } from 'react';

import { CustomIcon } from 'models/icon.interface';

const Verified = ({ color = 'current', size = 24 }: CustomIcon): ReactElement => (
  <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 24 24">
    <g id="verified_black_24dp" transform="translate(-0.417 -0.416)">
      <g id="Group_98" data-name="Group 98" transform="translate(0.151 0.151)">
        <rect id="Rectangle_77" data-name="Rectangle 77" width={size} height={size} transform="translate(0.266 0.265)" fill="none" />
      </g>
      <g id="Group_99" data-name="Group 99" transform="translate(1.092 1.601)">
        <path id="Path_102" data-name="Path 102" d="M23.383,12.183,20.9,9.344l.346-3.754-3.673-.834L15.651,1.5,12.191,2.985,8.732,1.5,6.809,4.746,3.137,5.57l.346,3.764L1,12.183l2.482,2.839-.346,3.764,3.673.834,1.923,3.246,3.459-1.5,3.459,1.485,1.923-3.246,3.673-.834L20.9,15.021Zm-13.135,4.8L6.382,13.109,7.888,11.6l2.36,2.371L16.2,8l1.506,1.506Z" transform="translate(-1 -1.5)" fill={color} />
      </g>
    </g>
  </svg>

);

export default Verified;

import { Box, Grid2 as Grid, Typography } from '@mui/material';

import { icons } from './icons';

const IconsList = () => {
  const sortedIcons = icons.sort((a, b) => a.name.localeCompare(b.name));

  return (
    <Grid container spacing={5} mt={5}>
      {sortedIcons.map(({ Icon, name }) => (
        <Grid size={2} key={name}>
          <Box display="flex" alignItems="center" flexDirection="column">
            <Icon size={30} color="#000" />
            <Typography variant="body2" sx={{ mt: 2 }}>
              {name}
            </Typography>
          </Box>
        </Grid>
      ))}
    </Grid>
  );
};

export default IconsList;

import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { resetStore } from 'store/actions';

export interface ConfettiState {
  isFired: boolean;
}

export const initialState: ConfettiState = {
  isFired: false,
};

export const confettiSlice = createSlice({
  name: 'confetti',
  initialState,
  reducers: {
    fireConfetti: (state, action: PayloadAction<boolean>) => {
      state.isFired = action.payload;
      return state;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(resetStore, () => initialState);
  },
});

export const {
  fireConfetti,
} = confettiSlice.actions;

export default confettiSlice.reducer;

const config = {
  id: 'campaigns',
  title: 'Campaigns',
  name: 'Campaigns app',
  brandName: 'Campaigns',
  appLinkSchema: 'campaigns',
  appTld: 'getcampaigns.app',
  tags: ['all', 'active'],
  TERMS_OF_SERVICE_URL: 'https://getcampaigns.app/tos',
  PRIVACY_STATEMENT_URL: 'https://getcampaigns.app/privacy',
  presentationSite: 'https://getcampaigns.app/',
};

export default config;

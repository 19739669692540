import { Box, Typography } from '@mui/material';
import { FC } from 'react';
import { useIntl } from 'react-intl';
import { useParams } from 'react-router-dom';

import { Root, Skeleton } from 'containers/RefreshCampaign/index.styled';

import { useAppDispatch } from 'hooks/useRedux';
import { getCampaignDetails, resetCampaignStore, setViewCampaign } from 'store/campaign/campaignSlice';
import { resetLeaderboard } from 'store/leaderboard/leaderboardSlice';

interface RefreshCampaignProps {
  reloadPage?: boolean;
}

const RefreshCampaign:FC<RefreshCampaignProps> = ({ reloadPage }) => {
  const intl = useIntl();
  const dispatch = useAppDispatch();
  const { campaignId } = useParams();

  const refreshCampaign = () => {
    if (reloadPage) {
      window.location.reload();
      return;
    }

    if (campaignId) {
      dispatch(resetCampaignStore());
      dispatch(setViewCampaign(false));
      dispatch(resetLeaderboard());
      dispatch(getCampaignDetails(campaignId));
    }
  };

  return (
    <Root>
      <Skeleton>
        <Box width="50%">
          <Typography variant="h2" textAlign="center" mb={2}>
            {intl.formatMessage({ id: 'label.issueLoadingCampaign' })}
          </Typography>
          <Typography
            role="presentation"
            onClick={refreshCampaign}
            variant="h2"
            textAlign="center"
            sx={{ cursor: 'pointer', textDecoration: 'underline' }}
          >
            {intl.formatMessage({ id: 'label.tryAgain' })}
          </Typography>
        </Box>
      </Skeleton>
    </Root>
  );
};

export default RefreshCampaign;

export const getApiError = (e: any) => {
  if (e?.response?.data?.data) {
    return {
      data: e?.response?.data?.data,
      status: e?.response?.data?.statusCode,
    };
  }

  return {
    data: 'Something went wrong!',
    status: 500,
  };
};

// TODO: improve this
export const handleApiErrors = (error: any) => {
  const message = error?.data
    ? error.data
    : 'Something went wrong, please try again!';

  console.log(message); // eslint-disable-line
};

export const isEmailValid = (email: string) => (
  /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(email)
);

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { getBountyLeaderboard } from 'services/Bounty';
import { resetStore } from 'store/actions';

import { ApiStatus } from 'constants/enums';
import { BountyLeaderboard } from 'models/leaderboard.interface';

export interface CampaignState {
  data: BountyLeaderboard | null;
  status: ApiStatus;
  error?: any; // TODO: check and define error interface
}

export const initialState: CampaignState = {
  data: null,
  status: ApiStatus.loading,
  error: undefined,
};

export const fetchLeaderboard = createAsyncThunk(
  'leaderboard/fetchLeaderboard',
  async ({ bountyId, excludeAnon }: { bountyId: string; excludeAnon: boolean}, thunkAPI) => {
    try {
      // eslint-disable-next-line camelcase
      const { data } = await getBountyLeaderboard(bountyId, { exclude_anon: excludeAnon });
      return data;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  },
);

export const refreshLeaderboard = createAsyncThunk(
  'leaderboard/refreshLeaderboard',
  async ({ bountyId, excludeAnon }: { bountyId: string; excludeAnon: boolean}, thunkAPI) => {
    try {
      // eslint-disable-next-line camelcase
      const { data } = await getBountyLeaderboard(bountyId, { exclude_anon: excludeAnon });
      return data;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  },
);

export const leaderboardSlice = createSlice({
  name: 'leaderboard',
  initialState,
  reducers: {
    resetLeaderboard: () => initialState,
  },
  extraReducers(builder) {
    builder
      .addCase(resetStore, () => initialState)
      .addCase(fetchLeaderboard.pending, (state) => {
        state.status = ApiStatus.loading;
      })
      .addCase(fetchLeaderboard.fulfilled, (state, action) => {
        state.data = action.payload;
        state.status = ApiStatus.idle;
      })
      .addCase(fetchLeaderboard.rejected, (state, action) => {
        state.data = null;
        state.status = ApiStatus.idle;
        state.error = action.payload;
      })
      .addCase(refreshLeaderboard.fulfilled, (state, action) => {
        state.data = action.payload;
      });
  },
});

export const {
  resetLeaderboard
} = leaderboardSlice.actions;

export default leaderboardSlice.reducer;

import { FC, ReactElement, useContext } from 'react';

import { FbUserContext } from 'context/FbUserContext';

import blackAppLogo from 'assets/images/blackLogo.png';
import whiteAppLogo from 'assets/images/whiteLogo.png';
import flavourConfig from 'configuration/env';
import { getCampaignsDashboard } from 'utils/links';

import { LogoImage, LogoWrapper } from './index.styled';

interface LogoProps {
  isDark?: boolean;
}

const Logo:FC<LogoProps> = ({ isDark }): ReactElement => {
  const { fbUser } = useContext(FbUserContext);
  
  const handleRedirect = (): void => {
    if (flavourConfig.campaignUrl) {
      window.location.href = getCampaignsDashboard(fbUser?.email);
    }
  };

  return (
    <LogoWrapper onClick={handleRedirect} className="appLogo">
      <LogoImage
        src={isDark ? blackAppLogo : whiteAppLogo}
        alt="Campaigns Logo"
      />
    </LogoWrapper>
  );
};

export default Logo;

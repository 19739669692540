import { BountyType, CompanySentsFolder } from 'constants/enums';

export const SENTS_FOLDER_BY_TYPE: Record<string, string> = {
  [BountyType.Product]: CompanySentsFolder.Products,
  [BountyType.BadgeCollection]: CompanySentsFolder.BadgeCollections,
  [BountyType.Promotion]: CompanySentsFolder.Promotions,
  [BountyType.Job]: CompanySentsFolder.Jobs,
  [BountyType.Autosuggestion]: CompanySentsFolder.Autosuggestion,
  [BountyType.PrivateListing]: CompanySentsFolder.Listings,
  [BountyType.Order]: CompanySentsFolder.Orders,
  [BountyType.News]: CompanySentsFolder.News,
  [BountyType.Banner]: CompanySentsFolder.Banners,
  [BountyType.Match]: CompanySentsFolder.Matches,
};

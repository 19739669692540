import { IdentityType, IdentityModeType } from 'constants/enums';
import { IdentityMode } from 'models/indentityMode.interface';

export const identityMode = {
  [IdentityModeType.ForcedAnon]: {
    forced: true,
    identityType: IdentityType.Anon,
  },
  [IdentityModeType.ForcedReal]: {
    forced: true,
    identityType: IdentityType.Real,
  },
  [IdentityModeType.Anon]: {
    forced: false,
    identityType: IdentityType.Anon,
  },
  [IdentityModeType.Real]: {
    forced: false,
    identityType: IdentityType.Real,
  },
};

export const getIdentityType = (mode?: IdentityMode) => {
  const type = mode ? mode.identityType : null;
  return getIdentityTypeAsEnum(type);
};

const getIdentityTypeAsEnum = (type: IdentityType|null) => {
  if (!type) {
    return IdentityType.Default;
  }

  return type;
};

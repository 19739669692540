import * as Sentry from '@sentry/react';
import { useEffect, StrictMode } from 'react';
import { createRoot } from 'react-dom/client';
import {
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from 'react-router-dom';

import 'assets/index.css';
import 'assets/scrollbar.css';
import flavourConfig from 'configuration/env';
import { enableSentry, getEnv } from 'utils/env';

import packageJson from '../package.json';
import App from './App';

const { SENTRY_CONFIG } = flavourConfig;

if (enableSentry()) {
  Sentry.init({
    ...SENTRY_CONFIG,
    enabled: true,
    environment: getEnv(),
    release: `${packageJson.name}@${packageJson.version}`,
    integrations: [
      Sentry.reactRouterV6BrowserTracingIntegration({
        useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes,
      }),
      Sentry.captureConsoleIntegration({
        levels: ['error'],
      }),
    ],
  });
}

createRoot(document.getElementById('root')!).render(
  <StrictMode>
    <App />
  </StrictMode>,
);

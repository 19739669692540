/* eslint max-len: 0 */
import { ReactElement } from 'react';

import { CustomIcon } from 'models/icon.interface';

const ArrowPlay = ({ color = 'current', size = 24 }: CustomIcon): ReactElement => (
  <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 24 24">
    <path fill="none" d="M0 0h24v24H0z" />
    <path d="M8 5v14l11-7z" fill={color} />
  </svg>
);

export default ArrowPlay;

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { isNil } from 'lodash';

import { getCurrencyDetails } from 'services/Account';
import {
  anonLikeBounty,
  getBountyById,
} from 'services/Bounty';
import { resetStore } from 'store/actions';
import { formatBounties, getCampaignCurrencyCode } from 'store/campaign/utils';

import { ApiStatus, BountyType, PointCurrency } from 'constants/enums';
import { Activity, Bounty, BountyActivity } from 'models/bounty.interface';
import { CampaignContent } from 'models/campaign.interface';
import { CustomCurrencyStats } from 'models/money.interface';
import { adjustLikesStat, sortTriviaSubBounties } from 'utils/bounty';
import { parseEncryptedDescription } from 'utils/yaml';

export interface CampaignState {
  campaignDetails?: Bounty;
  bounties?: Record<string, Bounty>;
  triviaBounties?: Record<string, Bounty[]>;
  campaign?: CampaignContent;
  viewCampaign: boolean;
  status: ApiStatus;
  refreshStatus: ApiStatus;
  currency: {
    data?: {
      meta: CustomCurrencyStats;
    };
    status: ApiStatus;
  };
  storiesEnded: boolean;
  error?: any;
  isPlayingTrivia?: boolean;
  highlightNextButton?: boolean;
  bountyActivities?: BountyActivity[];
  isViewed: boolean;
  closingCampaign: {
    data?: CampaignContent;
    status: ApiStatus;
  };
}

export const initialState: CampaignState = {
  campaignDetails: undefined,
  bounties: undefined,
  triviaBounties: undefined,
  campaign: undefined,
  viewCampaign: false,
  storiesEnded: false,
  refreshStatus: ApiStatus.idle,
  status: ApiStatus.loading,
  error: undefined,
  currency: {
    data: undefined,
    status: ApiStatus.idle,
  },
  isPlayingTrivia: false,
  highlightNextButton: false,
  bountyActivities: undefined,
  isViewed: false,
  closingCampaign: {
    data: undefined,
    status: ApiStatus.idle,
  }
};

export const getCampaignDetails = createAsyncThunk(
  'campaign/getCampaignDetails',
  async (campaignId: string, thunkAPI) => {
    try {
      const { data } = await getBountyById(campaignId);
      const currencyCode = getCampaignCurrencyCode(data);

      if (currencyCode !== PointCurrency.Systkn) {
        await thunkAPI.dispatch(fetchCurrencyDetails(currencyCode));
      }

      const { nextActivity } = parseEncryptedDescription<{ nextActivity: Activity }>(data?.bounty?.description);
      const closingCampaign = nextActivity?.bountyActivities?.find(({ type }) => type === BountyType.Campaign);

      if (closingCampaign?.id) {
        await thunkAPI.dispatch(getClosingCampaign(closingCampaign.id));
      }

      return data;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  },
);

export const getClosingCampaign = createAsyncThunk(
  'campaign/getClosingCampaign',
  async (campaignId: string, thunkAPI) => {
    try {
      const { data } = await getBountyById(campaignId);

      return data;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  },
);

export const refreshCampaignDetails = createAsyncThunk(
  'campaign/refreshCampaignDetails',
  async (campaignId: string, thunkAPI) => {
    try {
      const { data } = await getBountyById(campaignId);
      return data;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  },
);

export const refreshBountyDetails = createAsyncThunk(
  'campaign/refreshBountyDetails',
  async (bountyId: string, thunkAPI) => {
    try {
      const { data } = await getBountyById(bountyId);
      return data;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  },
);

export const getTriviaSubBounties = createAsyncThunk(
  'campaign/getTriviaSubBounties',
  async (bountyId: string, thunkAPI) => {
    try {
      const { data } = await getBountyById(bountyId);
      return {
        bountyId,
        subBounties: data?.subBounties || [],
      };
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  },
);

export const toggleLikedState = createAsyncThunk(
  'campaign/toggleLikedState',
  async ({ bounty }: {bounty: Bounty }, thunkAPI) => {
    try {
      const bountyClone = JSON.parse(JSON.stringify(bounty));
      const adjustment = bountyClone.likedAt ? -1 : 1;

      bountyClone.likedAt = bountyClone.likedAt ? null : new Date().getTime();
      bountyClone.stats = adjustLikesStat(bountyClone.stats, adjustment);

      thunkAPI.dispatch(updateSubBounty(bountyClone));

      await anonLikeBounty(bounty.id, !!bountyClone.likedAt);

      return bountyClone;
    } catch (e) {
      thunkAPI.dispatch(updateSubBounty(bounty));
      return thunkAPI.rejectWithValue(e);
    }
  },
);

export const toggleCampaignLikedState = createAsyncThunk(
  'campaign/toggleCampaignLikedState',
  async ({ bounty }: {bounty: Bounty }, thunkAPI) => {
    try {
      const bountyClone = JSON.parse(JSON.stringify(bounty));
      const adjustment = bountyClone.likedAt ? -1 : 1;

      bountyClone.likedAt = bountyClone.likedAt ? null : new Date().getTime();
      bountyClone.stats = adjustLikesStat(bountyClone.stats, adjustment);

      thunkAPI.dispatch(updateCampaignDetails(bountyClone));

      await anonLikeBounty(bounty.id, !!bountyClone.likedAt);

      return bountyClone;
    } catch (e) {
      thunkAPI.dispatch(updateCampaignDetails(bounty));
      return thunkAPI.rejectWithValue(e);
    }
  },
);

export const fetchCurrencyDetails = createAsyncThunk(
  'campaign/fetchCurrencyDetails',
  async (currencyCode: string, thunkAPI) => {
    try {
      const { data } = await getCurrencyDetails(currencyCode);
      return data;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  },
);

export const campaignSlice = createSlice({
  name: 'campaign',
  initialState,
  reducers: {
    setIsViewed: (state, action) => {
      state.isViewed = action.payload;
      return state;
    },
    updateSubBounty: (state, action) => {
      if (state.bounties?.[action.payload.id]) {
        state.bounties[action.payload.id] = action.payload;
      }

      return state;
    },
    updateBountyPersonalLayer: (state, action) => {
      if (state.bounties?.[action.payload.id]) {
        state.bounties[action.payload.id] = {
          ...state.bounties[action.payload.id],
          ...action.payload,
        };
      }

      return state;
    },
    updateCampaignDetails: (state, action) => {
      state.campaignDetails = action.payload;
      return state;
    },
    setViewCampaign: (state, action) => {
      state.viewCampaign = action.payload;
      return state;
    },
    updateTriviaSubBounty: (state, action) => {
      const {
        id: bountyId,
        parentBounty,
      } = action.payload;
      const parentId = parentBounty?.id;

      if (state.triviaBounties?.[parentId]) {
        const pos = state.triviaBounties?.[parentId]?.findIndex(({ id }) => id === bountyId);

        if (!isNil(pos)) {
          const newState = JSON.parse(JSON.stringify(state));
          const triviaBounties = [...newState.triviaBounties[parentId]];
          triviaBounties[pos] = action.payload;
          newState.triviaBounties[parentId] = triviaBounties;

          return newState;
        }
      }

      return state;
    },
    setStoriesEnded: (state, action) => {
      state.storiesEnded = action.payload;
      return state;
    },
    setIsPlayingTrivia: (state, action) => {
      state.isPlayingTrivia = action.payload;
      return state;
    },
    setHighlightNextButton: (state, action) => {
      state.highlightNextButton = action.payload;
      return state;
    },
    resetCampaignStore: () => initialState,
  },
  extraReducers(builder) {
    builder
      .addCase(resetStore, () => initialState)
      .addCase(getCampaignDetails.pending, (state) => {
        state.status = ApiStatus.loading;
      })
      .addCase(getCampaignDetails.fulfilled, (state, action) => {
        const {
          bounty,
          subBounties,
        } = action.payload;
        state.campaign = action.payload;
        state.campaignDetails = bounty;
        state.bounties = formatBounties(subBounties);
        state.status = ApiStatus.idle;

        if (bounty.description) {
          const { nextActivity } = parseEncryptedDescription<{ nextActivity: Activity }>(bounty.description);

          if (nextActivity?.bountyActivities) {
            state.bountyActivities = nextActivity.bountyActivities;
          }
        }
      })
      .addCase(getCampaignDetails.rejected, (state, action) => {
        state.campaign = undefined;
        state.status = ApiStatus.idle;
        state.error = action.payload;
      })
      .addCase(refreshCampaignDetails.pending, (state) => {
        state.refreshStatus = ApiStatus.loading;
      })
      .addCase(refreshCampaignDetails.fulfilled, (state, action) => {
        const {
          bounty,
          subBounties,
        } = action.payload;
        state.campaign = action.payload;
        state.campaignDetails = bounty;
        state.bounties = formatBounties(subBounties);
        state.refreshStatus = ApiStatus.idle;
      })
      .addCase(refreshCampaignDetails.rejected, (state) => {
        state.refreshStatus = ApiStatus.idle;
      })
      .addCase(refreshBountyDetails.fulfilled, (state, action) => {
        if (state.bounties?.[action.payload.id]) {
          state.bounties[action.payload.id] = action.payload;
        }
      })
      .addCase(getTriviaSubBounties.fulfilled, (state, action) => {
        if (state.triviaBounties) {
          state.triviaBounties[action.payload.bountyId] = sortTriviaSubBounties(action.payload.subBounties);
        } else {
          state.triviaBounties = {
            [action.payload.bountyId]: sortTriviaSubBounties(action.payload.subBounties),
          };
        }
      })
      .addCase(fetchCurrencyDetails.pending, (state) => {
        state.currency.status = ApiStatus.loading;
      })
      .addCase(fetchCurrencyDetails.fulfilled, (state, action) => {
        state.currency = {
          data: action.payload,
          status: ApiStatus.idle,
        };
      })
      .addCase(fetchCurrencyDetails.rejected, (state) => {
        state.currency.status = ApiStatus.idle;
      })
      .addCase(getClosingCampaign.pending, (state) => {
        state.closingCampaign.status = ApiStatus.loading;
      })
      .addCase(getClosingCampaign.fulfilled, (state, action) => {
        state.closingCampaign = {
          data: action.payload,
          status: ApiStatus.idle,
        };
      })
      .addCase(getClosingCampaign.rejected, (state) => {
        state.closingCampaign = {
          data: undefined,
          status: ApiStatus.idle,
        };
      });
  },
});

export const {
  updateSubBounty,
  updateBountyPersonalLayer,
  updateCampaignDetails,
  setViewCampaign,
  updateTriviaSubBounty,
  setStoriesEnded,
  setIsPlayingTrivia,
  setHighlightNextButton,
  resetCampaignStore,
  setIsViewed,
} = campaignSlice.actions;

export default campaignSlice.reducer;

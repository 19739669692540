import { PointCurrency } from 'constants/enums';
import { Bounty } from 'models/bounty.interface';
import { CampaignContent } from 'models/campaign.interface';
import { sortBounties } from 'utils/bounty';

export const formatBounties = (bounties: Bounty[]): Record<string, Bounty> => {
  const sortedBounties = sortBounties(bounties);
  return sortedBounties
    .reduce((acc: Record<string, Bounty>, curr: Bounty) => {
      acc[curr.id] = curr;
      return acc;
    }, {});
};

export const getCampaignCurrencyCode = (campaign?: CampaignContent) => {
  if (!campaign) {
    return PointCurrency.Systkn;
  }

  const { bounty, subBounties } = campaign;
  const currencyCode = bounty?.reward?.points ? Object.keys(bounty.reward.points)[0] : PointCurrency.Systkn;

  if (currencyCode !== PointCurrency.Systkn) {
    return currencyCode;
  }

  const bountyWithReward = subBounties?.find(({ reward }) => reward?.points);
  return bountyWithReward?.reward?.points
    ? Object.keys(bountyWithReward.reward.points)[0]
    : PointCurrency.Systkn;
};

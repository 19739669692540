/* eslint max-len: 0 */
import { ReactElement } from 'react';

import { CustomIcon } from 'models/icon.interface';

const ArrowOutward = ({ color = 'current', size = 24 }: CustomIcon): ReactElement => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    enableBackground="new 0 0 24 24"
    height={size}
    viewBox="0 0 24 24"
    width={size}
    fill={color}
  >
    <g>
      <rect fill="none" height="24" width="24" />
    </g>
    <g>
      <polygon points="6,6 6,8 14.59,8 5,17.59 6.41,19 16,9.41 16,18 18,18 18,6" />
    </g>
  </svg>
);

export default ArrowOutward;

import { captureException } from '@sentry/react';
import { ReactElement, useEffect } from 'react';
import { useRouteError } from 'react-router-dom';

import { Container, Root } from 'components/_ErrorBoundary/index.styled';
import RefreshCampaign from 'containers/RefreshCampaign';

import useStoryDimensions from 'hooks/useStoryDimensions';

const ErrorBoundary = (): ReactElement => {
  const error = useRouteError();
  const { width, height } = useStoryDimensions();

  useEffect(() => {
    captureException(error);
  }, []);

  return (
    <Root>
      <Container id="error-page" style={{ width, height }}>
        <RefreshCampaign reloadPage />
      </Container>
    </Root>
  );
};

export default ErrorBoundary;

import { lazy } from 'react';

import CustomLazyLoader from 'routes/CustomLazyLoader';

const StoriesView = lazy(
  () => CustomLazyLoader(
    () => import(/* webpackChunkName: 'StoriesView' */ 'containers/CampaignDetails'),
    'StoriesView',
  ),
);

export default StoriesView;

import { lazy } from 'react';

import CustomLazyLoader from 'routes/CustomLazyLoader';

const DefaultHome = lazy(
  () => CustomLazyLoader(
    () => import(/* webpackChunkName: 'DefaultHome' */ 'containers/Home'),
    'DefaultHome',
  ),
);

export default DefaultHome;

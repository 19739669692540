import { fetchSignInMethodsForEmail } from 'firebase/auth';
import { useContext, useEffect } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';

import { FbUserContext, FbUserDispatchContext } from 'context/FbUserContext';

import { AuthType } from 'hooks/useAuth';
import { useAppDispatch } from 'hooks/useRedux';
import {
  openModalSignIn, setAuthType, setData, setLoginMethod,
} from 'store/signinInvitation/signinInvitationSlice';

import { auth } from 'configuration/firebase';
import { CAMPAIGN_VIEW } from 'constants/clientRoutes';
import { LoginMethod } from 'constants/enums';
import { formatRoute } from 'utils/formatters';

const VisitApp = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { campaignId: campaignIdFromParams } = useParams();
  const [searchParams] = useSearchParams();
  const loginAs = searchParams?.get('loginAs') || '';
  const campaignId = searchParams?.get('campaign') || campaignIdFromParams || '';
  const { fbUser } = useContext(FbUserContext);
  const { onLogout } = useContext(FbUserDispatchContext);

  const prepareLoginData = async (email: string) => {
    try {
      const result = await fetchSignInMethodsForEmail(auth, email);

      if (result?.includes('password')) {
        dispatch(setLoginMethod(LoginMethod.Email));
        dispatch(setData({ email }));
        dispatch(setAuthType(AuthType.Login));
      }

      dispatch(openModalSignIn());
    } catch {
      dispatch(openModalSignIn());
    }
  };

  const seeCampaign = async () => {
    const decodedEmail = decodeURIComponent(loginAs);

    if (!decodedEmail) {
      if (!fbUser?.email) {
        dispatch(openModalSignIn());
      }

      navigate(formatRoute(CAMPAIGN_VIEW, { campaignId }), { replace: true });
      return;
    }

    if (fbUser?.email !== decodedEmail) {
      if (!fbUser?.isAnonymous) {
        await onLogout();
      }

      await prepareLoginData(decodedEmail);
    }

    navigate(formatRoute(CAMPAIGN_VIEW, { campaignId }), { replace: true });
  };

  useEffect(() => {
    if (!fbUser) {
      return;
    }

    seeCampaign();
  }, [fbUser?.uid]);

  return null;
};

export default VisitApp;

import palette from 'assets/theme/palette';

const getRandomInt = (max: number) => Math.floor(Math.random() * max);

export const getRandomThemeColor = (colors: { val: string }[]) => {
  const index = getRandomInt(colors.length);
  return colors[index].val;
};

export const getBackgroundColor = (searchParams: URLSearchParams) => {
  const backgroundColor = searchParams?.get('backgroundColor') || '';

  if (!backgroundColor || backgroundColor === 'default') {
    return palette.background.main;
  }

  return backgroundColor;
};

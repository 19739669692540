/* eslint max-len: 0 */
import { ReactElement } from 'react';

import { CustomIcon } from 'models/icon.interface';

const ArrowForward = ({ color = 'current', size = 24 }: CustomIcon): ReactElement => (
  <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 -960 960 960">
    <path d="m321-80-71-71 329-329-329-329 71-71 400 400L321-80Z" fill={color} />
  </svg>
);

export default ArrowForward;

import { UserRole } from 'constants/enums';
import { getClientId, AdminPin } from 'utils/api';

const ROLE_SEP = ',';

export const DEFAULT_USER_ROLES = [UserRole.User];

/**
 * Parse a string to get a list of role names
 * ex: "USER, ADMIN" => [USER, ADMIN]
 * @param roles {String} contains user roles as a string separated by comma
 * @returns {Array} - A list with role names
 */
export function parseRolesList(roles?: string|null): string[] {
  const roleList: string[] = [];

  if (!roles) {
    return roleList;
  }

  const parts = roles.split(ROLE_SEP);

  parts.forEach((role) => {
    const currentRole = role ? role.toUpperCase() : null;

    if (currentRole && !roleList.includes(currentRole)) {
      roleList.push(currentRole);
    }
  });

  return roleList;
}

export function renderRolesList(roles: string[]) {
  return roles.toString();
}

export function isAdmin(roles = '') {
  const rolesAsList = parseRolesList(roles);
  return rolesAsList.includes(UserRole.Admin);
}

export function isSystemAdmin(roles = '') {
  const rolesAsList = parseRolesList(roles);
  return rolesAsList.includes(UserRole.Admin) && getClientId() === AdminPin;
}

/* eslint max-len: 0 */
import { ReactElement } from 'react';

import { CustomIcon } from 'models/icon.interface';

const BlackCircle = ({ color = 'current', size = 20 }: CustomIcon): ReactElement => (
  <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 24 24">
    <path fill={color} d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8zM321-80-71-71 329-329-329-329 71-71 400 400L321-80Z" />
  </svg>
);

export default BlackCircle;

import {
  configureStore, ThunkAction, Action,
} from '@reduxjs/toolkit';

import campaignReducer from 'store/campaign/campaignSlice';
import confettiReducer from 'store/confetti/confettiSlice';
import leaderboardReducer from 'store/leaderboard/leaderboardSlice';
import signinInvitationReducer from 'store/signinInvitation/signinInvitationSlice';

export const store = configureStore({
  reducer: {
    campaign: campaignReducer,
    confetti: confettiReducer,
    leaderboard: leaderboardReducer,
    signinInvitation: signinInvitationReducer,
  },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;

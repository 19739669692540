import {
  ResponseType, BountyState, BountyType, SummaryProgressState,
} from 'constants/enums';
import { Bounty, Summary } from 'models/bounty.interface';
import { BountyResponse } from 'models/bountyResponse.interface';
import { SurveyAnswer, SurveyResult, SurveyOption } from 'models/survey.interface';
import { isBountyExpired } from 'utils/bounty';

export const QUESTION_SEP = '\n--';
export const PROPERTIES_SEP = '\n\n';
export const BULLET_SEP = ':';
export const BULLET_SEPS = '.:>-';
export const LINE_PAT = /(.*) \(=([0-9]+)?(, ?)?(reward:<(.*)>)?\)$/;
export const TIMEOUT_OPTION = 'T: Timeout';

export const SURVEY_TYPES = [
  BountyType.Survey,
  BountyType.Score,
  BountyType.Checklist,
  BountyType.MCQ,
  BountyType.Funding,
  BountyType.Lottery,
];

export const isSurveyType = (type: BountyType) => SURVEY_TYPES.includes(type);

export const stringToSurvey = (description: { text: string }, type: string) => {
  const { text } = description;

  if (!text) {
    return null;
  }

  return parse(text, type);
};

const stringToSurveyOption = (string: string): SurveyOption => {
  const answer: SurveyOption = { code: '', text: '' };
  const str = string.trim();
  let txt = null;

  if (str) {
    if (str.length === 1) {
      answer.code = str;
    } else {
      const sep = str.charAt(1);

      if (BULLET_SEPS.indexOf(sep) >= 0) {
        answer.code = str.substring(0, 1);
        txt = str.substring(2).trim();
      } else {
        txt = str;
      }
    }
  }

  if (txt !== null) {
    const m = txt.match(LINE_PAT);

    if (m) {
      answer.text = m[1] || '';
      const weightStr = m[2];
      const rewardStr = m[5];

      if (weightStr?.trim()?.length) {
        answer.weight = +weightStr;
      }

      if (rewardStr?.trim()?.length) {
        // TODO: add this later
        // answer.reward = rewardStr;
      }
    } else {
      answer.text = txt || '';
    }
  }

  return answer;
};

export const parse = (str: string, type: string): { question: string; options: SurveyOption[]; type: string } => {
  const indexOfQuestionSep = str.indexOf(QUESTION_SEP);
  let question = null;
  const options: SurveyOption[] = [];

  if (type === BountyType.Survey) {
    type = BountyType.Poll;
  }

  if (indexOfQuestionSep < 0) {
    question = str.trim();
  } else {
    question = str.substring(0, indexOfQuestionSep).trim();
    const optionsString = str.substring(indexOfQuestionSep + QUESTION_SEP.length).trim();
    const rawOptions = optionsString.trim().split('\n');

    rawOptions.forEach((option) => (
      options.push(stringToSurveyOption(option))
    ));
  }

  if (question) {
    if (question.startsWith(`${type}${BULLET_SEP}`)) {
      question = question.substring(question.indexOf(BULLET_SEP) + 1).trim();
    }
  }

  const [description, ...additionalInfo] = question.split(PROPERTIES_SEP);

  if (additionalInfo?.length > 1) {
    question = description;
  }

  return {
    question,
    options,
    type,
  };
};

export const getMCQProperties = (str: string) => {
  const indexOfQuestionSep = str?.indexOf(QUESTION_SEP);
  let question = null;

  if (indexOfQuestionSep < 0) {
    question = str?.trim();
  } else {
    question = str?.substring(0, indexOfQuestionSep)?.trim();
  }

  if (question && question.startsWith(`${BountyType.MCQ}${BULLET_SEP}`)) {
    question = question.substring(question.indexOf(BULLET_SEP) + 1).trim();
  }

  const [, description, timeLimit, reward] = question.match(/([\s\S]*?)\s+time_limit:(\d+)\s+reward_type:(\w+)/) || [];
  return {
    question: description,
    timeLimit: timeLimit ? +timeLimit / 1000 : 0,
    reward: reward || 0,
  };
};

export const getSurveyChoices = (outboundResponses: Record<string, BountyResponse>) => {
  const surveyChoices: string[] = [];

  if (outboundResponses) {
    Object.values(outboundResponses).forEach((outboundRes) => {
      if (outboundRes.type === ResponseType.Choice && outboundRes.choice) {
        surveyChoices.push(outboundRes.choice);
      }
    });
  }

  return surveyChoices;
};

export const isSurveyAnswered = (bountyType: BountyType, outboundResponses: Record<string, BountyResponse>) => {
  if (bountyType === BountyType.Checklist) {
    return false;
  }

  return getSurveyChoices(outboundResponses).length > 0;
};

export const isSurveyCompleted = (bountyType?: BountyType, summary?: Summary) => {
  if (bountyType === BountyType.Checklist) {
    return false;
  }

  return summary && summary.surveyResult && summary.progressState === SummaryProgressState.Completed;
};

export const isSurveyAnswerable = (bounty: Bounty, isOutbound: boolean) => {
  const {
    state,
    type,
    expiresAt,
    outboundResponses,
    summary,
  } = bounty;

  return !isOutbound
    && !isBountyExpired(state, expiresAt)
    && ![BountyState.Draft, BountyState.Closed].includes(state)
    && (!outboundResponses || (outboundResponses && !isSurveyAnswered(type, outboundResponses)))
    && !isSurveyCompleted(type, summary);
};

export const getTotalCount = (answers: Record<string, SurveyAnswer>) => {
  if (answers) {
    return Object
      .values(answers)
      .reduce((acc, { count }) => (count ? acc + count : acc), 0) || 0;
  }

  return 0;
};

export const getAnswerCount = (answers: Record<string, SurveyAnswer>, code: string) => {
  const answer = answers[code] || {};
  return answer.count || 0;
};

export const isCorrectChoice = (option: SurveyOption, correctWeight: number): boolean => (
  !!(option.weight && +option.weight === correctWeight)
);

export const isCorrectAnswer = (answer: SurveyAnswer): boolean => (
  !!(answer && answer.maxPoints && answer.maxPoints === answer.answerPoints)
);

export const getPercentage = (totalCount: number, surveyAnswer: SurveyAnswer): number => {
  if (totalCount === 0 || !surveyAnswer || (surveyAnswer && !surveyAnswer.count)) {
    return 0;
  }

  return surveyAnswer.count ? Math.round((surveyAnswer.count * 100.0) / totalCount) : 0;
};

export const getLeadingSurveyAnswer = (surveyResult: SurveyResult): SurveyAnswer|null => {
  let maxCount = -1;
  let maxAnswer = null;

  if (surveyResult?.answers) {
    Object.values(surveyResult.answers).forEach((answer) => {
      if (answer.count && maxCount < answer.count) {
        maxCount = answer.count;
        maxAnswer = answer;
      }
    });
  }

  return maxAnswer;
};

export const getAverageScore = (surveyResults: Record<string, SurveyAnswer>) => {
  const { answers } = surveyResults;
  let totalScore = 0;
  let totalCnt = 0;

  if (answers) {
    Object.values(answers).forEach(({ score, count, weight }) => {
      if (weight) {
        totalScore += score;
        totalCnt += count;
      }
    });
  }

  if (totalCnt) {
    const average = totalScore / totalCnt;
    return average % 1 === 0 ? average : average.toFixed(1);
  }

  return 0;
};

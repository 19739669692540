import { UserRole, AllowMode as AllowModeEnum, ProductFlavours as ProductFlavourEnum } from 'constants/enums';
import { op } from 'constants/op';
import { DefaultRoleDef, RoleAuth } from 'models/baseSettings.interface';
import { getInstance } from 'utils/productConfig';
import * as ProductFlavour from 'utils/productFlavour';

const flavour = ProductFlavour.getCurrent();
let productConfig = {};

export const AllowMode = {
  isSupreme: (mode: AllowModeEnum) => (mode === AllowModeEnum.Always || mode === AllowModeEnum.Never),
  isAllowed: (mode: AllowModeEnum) => (mode === AllowModeEnum.Always || mode === AllowModeEnum.Should),
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  asEnum: (mode: AllowModeEnum) => (!mode ? AllowModeEnum.Default : (AllowModeEnum[mode] || AllowModeEnum.Default)),
};

const ADMIN_ROLE_DEF = {
  isDefault: true,
  forbiddenOps: [],
  isAllowed: () => true,
  isForbidden: () => false,
  isAllowedUnrestrictedAccess: () => true,
  getExplicitlyAllowed: () => null,
};

const USER_ROLE_DEF = {
  isDefault: true,
  forbiddenOps: [],
  isForbidden: () => false,
  isAllowed: (operation: string) => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const mode = productConfig?.getAllowed(operation);

    if (mode !== AllowModeEnum.Default) {
      return AllowMode.isAllowed(mode);
    }

    return false;
  },
  isAllowedUnrestrictedAccess: () => true,
  getExplicitlyAllowed: () => null,
};

/**
 * This function returns:
 * {({isFollowUserEnabled, getAllowed, listTypes, identityMode, accessMode, bountyTypes, hideBulletins, isSocialPostEnabled}&{getAllowed})
 * |({isFollowUserEnabled, getAllowed, listTypes, identityMode, accessMode, bountyTypes, hideBulletins, isSocialPostEnabled}&{isFollowUserEnabled})
 * |{isFollowUserEnabled, getAllowed, listTypes, identityMode, accessMode, bountyTypes, hideBulletins, isSocialPostEnabled}}
 */
export function getDefaults(productFlavour: ProductFlavourEnum) {
  const config = getInstance(productFlavour);
  const adminOps: Record<string, RoleAuth> = {};
  const defaultOps: Record<string, RoleAuth> = {};

  op.forEach((operation) => {
    const mode = config.getAllowed(operation.name);

    if (AllowMode.isSupreme(mode) && !AllowMode.isAllowed(mode)) {
      return;
    }

    adminOps[operation.name] = { allow: true };

    if (!AllowMode.isAllowed(mode)) {
      return;
    }

    if (operation.enabledByDefault) {
      defaultOps[operation.name] = { allow: true };
    }
  });

  return {
    config,
    adminOps,
    defaultOps,
  };
}

/**
 * Get default definition of user role (user or admin)
 */
export const getRoleDef = (role: string): DefaultRoleDef | null => {
  const { config, adminOps, defaultOps } = getDefaults(flavour);
  productConfig = config;

  if (!role) {
    return null;
  }

  if (role === UserRole.User) {
    return {
      ...USER_ROLE_DEF,
      ops: defaultOps,
    };
  }

  if (role === UserRole.Admin) {
    return {
      ...ADMIN_ROLE_DEF,
      ops: adminOps,
    };
  }

  return null;
};

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { User } from 'firebase/auth';

import { resetStore } from 'store/actions';

export interface SigninInvitation {
  isInvitationOpen: boolean;
  isSignInOpen: boolean;
  isSubmitting: boolean;
  claimTokens?: boolean;
  type: string;
  values?: Record<string, string>;
  data?: Record<string, string>;
  authType: string;
  loginMethod: string;
  tempUser?: User;
}

export const initialState: SigninInvitation = {
  isInvitationOpen: false,
  isSignInOpen: false,
  isSubmitting: false,
  claimTokens: false,
  type: '',
  values: {},
  data: {},
  authType: '',
  loginMethod: '',
  tempUser: undefined,
};

export const signinInvitationSlice = createSlice({
  name: 'signinInvitation',
  initialState,
  reducers: {
    openModalInvitation: (state, action: PayloadAction<{ type: string; values?: Record<string, string> }>) => ({
      ...state,
      isInvitationOpen: true,
      type: action.payload.type,
      values: action.payload.values || {},
    }),
    closeModalInvitation: (state) => ({
      ...state,
      isInvitationOpen: false,
      type: '',
      values: {},
    }),
    setTempUser: (state, action: PayloadAction<User|undefined>) => ({
      ...state,
      tempUser: action.payload,
    }),
    setAuthType: (state, action: PayloadAction<string>) => ({
      ...state,
      authType: action.payload,
    }),
    setClaimTokens: (state, action: PayloadAction<boolean>) => ({
      ...state,
      claimTokens: action.payload,
    }),
    setLoginMethod: (state, action: PayloadAction<string>) => ({
      ...state,
      loginMethod: action.payload,
    }),
    setData: (state, action: PayloadAction<Record<string, string>>) => ({
      ...state,
      data: {
        ...state.data,
        ...action.payload,
      },
    }),
    setIsSubmitting: (state, action: PayloadAction<boolean>) => ({
      ...state,
      isSubmitting: action.payload,
    }),
    openModalSignIn: (state, action: PayloadAction<Record<string, any>|undefined>) => ({
      ...state,
      isInvitationOpen: false,
      isSignInOpen: true,
      isSubmitting: false,
      type: '',
      values: {},
      claimTokens: Boolean(action?.payload?.claimTokens),
    }),
    closeModalSignIn: () => initialState,
  },
  extraReducers(builder) {
    builder
      .addCase(resetStore, () => initialState);
  },
});

export const {
  openModalInvitation,
  closeModalInvitation,
  openModalSignIn,
  closeModalSignIn,
  setIsSubmitting,
  setAuthType,
  setLoginMethod,
  setData,
  setTempUser,
  setClaimTokens,
} = signinInvitationSlice.actions;

export default signinInvitationSlice.reducer;

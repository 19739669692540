import { signOut } from 'firebase/auth';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { auth } from 'configuration/firebase';

const Logout = () => {
  const navigate = useNavigate();

  useEffect(() => {
    handleLogout();
  }, []);

  const handleLogout = async () => {
    await signOut(auth);
    navigate(-1);
  };

  return null;
};

export default Logout;

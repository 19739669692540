import { cloneDeep } from 'lodash';

import { AllowMode } from 'constants/enums';
import { ALL } from 'constants/op';
import { BaseSettings, CapInfo } from 'models/baseSettings.interface';
import { Settings } from 'models/settings.interface';
import { UserData } from 'models/userData.interface';
import {
  getAllowModeAsEnum,
  getRoleDefByName,
  mergeCapInfo,
  getExplicitlyAllowed,
  isForbidden,
} from 'utils/baseSettings';
import * as ProductDefaults from 'utils/productDefaults';
import * as ProductFlavour from 'utils/productFlavour';
import * as RoleDef from 'utils/roleDef';

const productFlavour = ProductFlavour.getCurrent();
const { config: productDefaults } = ProductDefaults.getDefaults(productFlavour);

export const getSettingsPriorityOrder = (settings: Settings) => {
  const settingsPriorityOrder = [];

  if (settings?.userSettings) {
    settingsPriorityOrder.push(settings.userSettings);
  }

  if (settings?.companySettings) {
    settingsPriorityOrder.push(settings.companySettings);
  }

  if (settings?.orgTypeSettings) {
    settingsPriorityOrder.push(settings.orgTypeSettings);
  }

  if (settings?.systemSettings) {
    settingsPriorityOrder.push(settings.systemSettings);
  }

  return settingsPriorityOrder;
};

export const findFirst = <T>(settings: Settings, callback: (s: BaseSettings) => T|null): T|null => {
  let foundValue = null;
  const settingsPriorityOrder = getSettingsPriorityOrder(settings);

  settingsPriorityOrder.find((s) => {
    foundValue = callback(s);
    return foundValue;
  });

  return foundValue !== null && foundValue !== undefined ? foundValue : null;
};

export const findAllByKey = <T>(
  settings: Settings,
  callback: (s: BaseSettings) => Record<string, T>|null,
  key: string,
): T[] => {
  const settingsPriorityOrder = getSettingsPriorityOrder(settings);
  const list: T[] = [];

  settingsPriorityOrder.forEach((s) => {
    const result: Record<string, T>|null = callback(s);

    if (result && result[key]) {
      list.push(result[key]);
    }
  });

  return list;
};

export const mergeAll = <T>(
  settings: Settings,
  fetcher: (s: BaseSettings) => Record<string, T>|null,
  merger: any,
  key: string,
): T|null => {
  const list: T[] = findAllByKey<T>(settings, fetcher, key);

  if (!list || !list.length) {
    return null;
  }

  if (list.length === 1) {
    return list[0];
  }

  list.reverse();
  let finalValue = list[0];

  list.forEach((item) => {
    finalValue = merger(finalValue, item);
  });

  return finalValue;
};

export const mapAll = <T>(settings: Settings, fetcher: (s: BaseSettings) => Record<string, T>|null, list = {}) => {
  const settingsPriorityOrder = getSettingsPriorityOrder(settings);
  const listClone: Record<string, T> = cloneDeep(list);

  settingsPriorityOrder.forEach((s) => {
    const mapResult = fetcher(s);

    if (!mapResult) {
      return;
    }

    Object.keys(mapResult).forEach((key) => {
      if (!listClone[key]) {
        listClone[key] = mapResult[key];
      }
    });
  });

  return listClone;
};

export const forEachSettings = (settings: Settings, callback: any) => {
  const settingsPriorityOrder = getSettingsPriorityOrder(settings);
  settingsPriorityOrder.forEach((item) => {
    callback(item);
  });
};

// ------------------------------------------------------------------------ //
//     ********************  App-relevant methods ********************      //
//  ----------------------------------------------------------------------- //
export const getCapInfo = (settings: Settings, op: string): CapInfo|null => (
  mergeAll<CapInfo>(settings, (s: BaseSettings) => (s.capInfos), mergeCapInfo, op)
);

function getRoleDef({ settings, role }: { settings: Settings; role: string }) {
  let roleDef = findFirst(settings, (s: BaseSettings) => getRoleDefByName(s.roles, role));

  if (!roleDef && productDefaults) {
    roleDef = ProductDefaults.getRoleDef(role);
  }

  return roleDef;
}

/**
 * Prepares an array with all the role names a user can take base on the 'roles' property from user data and if the
 * array is empty, the user will be assigned an array with the default role (user role)
 */
const getMyRoles = (userData: UserData) => {
  const roles = RoleDef.parseRolesList(userData ? userData.roles : null);
  return roles.length ? roles : RoleDef.DEFAULT_USER_ROLES;
};

/**
 * Check if a user is allowed to do a certain operation
 * settings {Object} contains userSettings, companySettings, orgTypeSettings and systemSettings
 */
export const isAllowed = ({ settings, userData, op }: { settings: Settings; userData: UserData; op: string }) => {
  const capInfo = getCapInfo(settings, op);
  const allowMode = capInfo ? getAllowModeAsEnum(capInfo.allowMode) : productDefaults.getAllowed(op);

  if (ProductDefaults.AllowMode.isSupreme(allowMode)) {
    return ProductDefaults.AllowMode.isAllowed(allowMode);
  }

  const myRoles = getMyRoles(userData);

  let isOpAllowed = null;
  let isOpForbidden = null;
  const rolesDef = myRoles
    .map((role) => getRoleDef({ settings, role }))
    .filter((def) => !!def);

  rolesDef.find((role) => {
    if (!role) {
      return false;
    }

    const fn = role?.getExplicitlyAllowed || getExplicitlyAllowed;
    const isExplicitlyAllowed = fn(op, role.ops);

    if (role && isExplicitlyAllowed !== null) {
      isOpAllowed = isExplicitlyAllowed;
      return isExplicitlyAllowed;
    }

    return false;
  });

  if (isOpAllowed !== null) {
    return isOpAllowed;
  }

  rolesDef.find((role) => {
    if (role?.isDefault && role?.ops?.[op]) {
      isOpAllowed = true;
      return true;
    }

    return false;
  });

  rolesDef.find((role) => {
    const fn = (role && role.isForbidden) || isForbidden;

    if (fn(op)) {
      isOpForbidden = true;
      return isOpForbidden;
    }

    return false;
  });

  if (isOpForbidden) {
    return false;
  }

  rolesDef.find((role) => {
    if (!role) {
      return false;
    }

    const fn = role.getExplicitlyAllowed || getExplicitlyAllowed;
    const isAllExplicitAllowed = fn(ALL.name, role.ops) || null;

    if (role && isAllExplicitAllowed !== null) {
      isOpAllowed = isAllExplicitAllowed;
      return isAllExplicitAllowed;
    }

    return false;
  });

  if (isOpAllowed !== null) {
    return isOpAllowed;
  }

  if (allowMode !== AllowMode.Default) {
    return ProductDefaults.AllowMode.isAllowed(allowMode);
  }

  return false;
};

export const getOrganizationType = (settings: Settings) => findFirst(settings, (s) => s.organizationType);

import flavourConfig from 'configuration/env';

import packageJson from '../../package.json';

export const ClientPin = '1515';
export const AdminPin = '101';

export function getUserAgent() {
  return `${flavourConfig.id}/release/web/${packageJson.version}/0 ${navigator.userAgent}`;
}

export function getBaseUrl() {
  let baseUrl = `${flavourConfig.API_BASE_URL}`;

  if (!baseUrl) {
    baseUrl = `https://api.${flavourConfig.appTld}`;
  }

  return baseUrl;
}

export const getClientId = () => ClientPin;

export const getRequestMeta = (action: string, userId: string) => {
  const now = new Date().getTime();
  return ({
    action: action,
    userId,
    clientId: ClientPin,
    createdAt: now,
    addedAt: now,
    agent: getUserAgent(),
    restEvent: false,
  });
};

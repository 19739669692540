
export const variants = {
  title1: {
    fontSize: '40px',
    fontWeight: 700,
    lineHeight: '44px',
    display: 'block',
  },
  h1: {
    fontSize: '24px',
    fontWeight: 700,
    lineHeight: '28px',
  },
  h2: {
    fontSize: '18px',
    fontWeight: 700,
    lineHeight: '24px',
  },
  subtitle: {
    fontSize: '22px',
    fontWeight: 400,
    lineHeight: '28px',
  },
  subtitle1: {
    fontSize: '18px',
    fontWeight: 400,
    lineHeight: '24px',
  },
  subtitle2: {
    fontSize: '16px',
    fontWeight: 600,
    lineHeight: '21px',
  },
  body1: {
    fontSize: '15px',
    fontWeight: 400,
    lineHeight: '21px',
  },
  body2: {
    fontSize: '14px',
    fontWeight: 400,
    lineHeight: '18px',
  },
  body3: {
    fontSize: '12px',
    fontWeight: 400,
    lineHeight: '14px',
  },
  caption: {
    fontSize: '10px',
    fontWeight: 400,
    lineHeight: '14px',
  },
};

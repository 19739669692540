import { onAuthStateChanged, signInAnonymously, User } from 'firebase/auth';
import { get } from 'firebase/database';
import {
  FC,
  ReactNode,
  createContext,
  useState,
  useMemo,
  useEffect,
} from 'react';

import Loader from 'components/Loader';
import { LeaderboardProvider } from 'context/LeaderboardContext';

import { useAppDispatch } from 'hooks/useRedux';
import {
  getMyCompanySettingsRef,
  getOrgTypeSettingsRef,
  getSystemSettingsRef,
  getUserDataRef,
} from 'services/FirebaseRefs';
import { updateUserHeadsUpTimerConfirmation } from 'services/User';
import { resetStore } from 'store/actions';

import { auth } from 'configuration/firebase';
import { ConfirmationType } from 'constants/enums';
import { Settings } from 'models/settings.interface';
import { UserData } from 'models/userData.interface';
import { handleApiErrors } from 'utils/error';
import { getOrganizationType } from 'utils/settings';

interface FbUserContextProps {
  fbUser: User | null;
  mergedUser: User | null;
  userData: UserData | null;
  settings: Settings | null;
  isTimerConfirmationEnabled: boolean;
}

const FbUserContext = createContext<FbUserContextProps>({
  fbUser: null,
  mergedUser: null,
  userData: null,
  settings: null,
  isTimerConfirmationEnabled: false,
});

interface FbUserDispatchContextProps {
  setFbUser: (user: User | null) => void;
  setMergedUser: (user: User | null) => void;
  onLogout: () => Promise<any>;
  onDisableTimerConfirmation: (enabled: boolean) => void;
}

const FbUserDispatchContext = createContext<FbUserDispatchContextProps>({
  setFbUser: () => null,
  setMergedUser: () => null,
  onLogout: async () => null,
  onDisableTimerConfirmation: () => null,
});

interface FbUserProviderProps {
  children: ReactNode;
}

const FbUserProvider: FC<FbUserProviderProps> = ({ children }) => {
  const dispatch = useAppDispatch();
  const [settings, setSettings] = useState<Settings | null>(null);
  const [fbUser, setFbUser] = useState<User | null>(null);
  const [mergedUser, setMergedUser] = useState<User | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isTimerConfirmationEnabled, setIsTimerConfirmationEnabled] = useState(false);

  useEffect(() => {
    const unregisterAuthObserver = onAuthStateChanged(auth, async (userResponse) => {
      if (userResponse) {
        setFbUser(userResponse);

        if (!userResponse.isAnonymous) {
          await fetchData(userResponse.uid);
        } else if (mergedUser) {
          setMergedUser(null);
        }

        if (userResponse.isAnonymous) {
          setIsTimerConfirmationEnabled(true);
        }

        setIsLoading(false);
      } else {
        setFbUser({} as User);
        await signInAnonymously(auth);
        setIsTimerConfirmationEnabled(true);
      }
    });

    return () => {
      unregisterAuthObserver();
    };
  }, []);

  const handleLogout = async () => {
    setIsLoading(true);
    return auth.signOut()
      .then(() => {
        dispatch(resetStore());
      });
  };

  const fetchData = async (userId: string) => {
    let settingsResult: Settings = {
      userData: null,
      userSettings: null,
      systemSettings: null,
      companySettings: null,
      orgTypeSettings: null,
    };
    try {
      const [userDataResult, systemSettingsResult] = await Promise.allSettled([
        get(getUserDataRef(userId)),
        get(getSystemSettingsRef()),
      ]);
      const userDataVal = userDataResult?.status === 'fulfilled' ? userDataResult.value.val() : null;
      settingsResult = {
        ...settingsResult,
        userData: userDataVal,
        userSettings: userDataVal?.settings || null,
        systemSettings: systemSettingsResult?.status === 'fulfilled' ? systemSettingsResult.value.val() : null,
      };

      const showLetsGoDialog = !userDataVal?.settings?.confirmations?.[ConfirmationType.HideLetsGoDialog]?.confirmed;
      setIsTimerConfirmationEnabled(showLetsGoDialog);

      if (userDataVal && userDataVal.company) {
        const companySettingsResult = await get(getMyCompanySettingsRef(userDataVal.company.id));
        settingsResult.companySettings = companySettingsResult.val() || null;
      }

      const orgType = getOrganizationType(settingsResult);

      if (orgType) {
        const orgTypeSettingsResult = await get(getOrgTypeSettingsRef(orgType));
        settingsResult.orgTypeSettings = orgTypeSettingsResult.val() || null;
      }
    } catch (e) {
      console.error(e);
    } finally {
      setSettings(settingsResult);
    }
  };

  const handleDisableTimerConfirmation = (enable: boolean) => {
    if (fbUser?.uid && !fbUser.isAnonymous) {
      setIsTimerConfirmationEnabled(enable);
      handleUpdateUserHeadsUpTimerConfirmation(fbUser.uid);
    } else {
      setIsTimerConfirmationEnabled(enable);
    }
  };

  const handleUpdateUserHeadsUpTimerConfirmation = async (userId: string) => {
    try {
      await updateUserHeadsUpTimerConfirmation(userId);
      const result = await get(getUserDataRef(userId));
      const userDataVal = result.val();
      setSettings({
        ...settings,
        userData: userDataVal,
        userSettings: userDataVal?.settings || null,
      } as Settings);
    } catch (e) {
      handleApiErrors(e);
    }
  };

  const userStore = useMemo(() => ({
    fbUser,
    userData: settings?.userData || null,
    settings,
    mergedUser,
    isTimerConfirmationEnabled,
  }), [fbUser, settings, mergedUser, isTimerConfirmationEnabled]);

  const dispatchContextStore = useMemo(() => ({
    setFbUser,
    setMergedUser,
    onLogout: handleLogout,
    onDisableTimerConfirmation: handleDisableTimerConfirmation,
  }), [setFbUser, setMergedUser, handleDisableTimerConfirmation]);

  return (
    <FbUserContext.Provider value={userStore}>
      <FbUserDispatchContext.Provider value={dispatchContextStore}>
        <LeaderboardProvider>
          {isLoading ? <Loader /> : children}
        </LeaderboardProvider>
      </FbUserDispatchContext.Provider>
    </FbUserContext.Provider>
  );
};

export {
  FbUserProvider,
  FbUserContext,
  FbUserDispatchContext,
};

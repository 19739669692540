/* eslint max-len: 0 */
import { ReactElement } from 'react';

import { CustomIcon } from 'models/icon.interface';

const ArrowPause = ({ color = 'current', size = 24 }: CustomIcon): ReactElement => (
  <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 24 24">
    <path fill="none" d="M0 0h24v24H0z" />
    <path d="M6 19h4V5H6v14zm8-14v14h4V5h-4z" color={color} />
  </svg>
);

export default ArrowPause;

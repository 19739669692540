/* eslint max-len: 0 */
import { ReactElement } from 'react';

import { CustomIcon } from 'models/icon.interface';

const ArrowBack = ({ color = 'current', size = 24 }: CustomIcon): ReactElement => (
  <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 -960 680 960">
    <path d="M400-80 0-480l400-400 71 71-329 329 329 329-71 71Z" fill={color} />
  </svg>
);

export default ArrowBack;

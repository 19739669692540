/* eslint max-len: 0 */
import { ReactElement } from 'react';

import { CustomIcon } from 'models/icon.interface';

const Google = ({ size = 24 }: CustomIcon): ReactElement => (
  <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 30 30">
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M16 12h30v30H16z" data-name="Rectangle 104" />
      </clipPath>
    </defs>
    <g clipPath="url(#a)" data-name="Group 238" transform="translate(-16 -12)">
      <path
        fill="#4285f4"
        d="M45.4 27.341a17.235 17.235 0 0 0-.273-3.068H31v5.8h8.073A6.9 6.9 0 0 1 36.08 34.6v3.764h4.848A14.629 14.629 0 0 0 45.4 27.341Z"
        data-name="Path 172"
      />
      <path
        fill="#34a853"
        d="M31 42a14.321 14.321 0 0 0 9.932-3.634l-4.848-3.764a9.05 9.05 0 0 1-13.477-4.752h-5.011v3.886A14.994 14.994 0 0 0 31 42Z"
        data-name="Path 173"
      />
      <path
        fill="#fbbc04"
        d="M22.607 29.855a8.869 8.869 0 0 1 0-5.7v-3.891H17.6a15.019 15.019 0 0 0 0 13.473l5.007-3.882Z"
        data-name="Path 174"
      />
      <path
        fill="#e94235"
        d="M31 17.966a8.1 8.1 0 0 1 5.732 2.243l4.3-4.3A14.416 14.416 0 0 0 31 12a14.994 14.994 0 0 0-13.4 8.264l5.011 3.886A8.94 8.94 0 0 1 31 17.966Z"
        data-name="Path 175"
      />
    </g>
  </svg>
);

export default Google;


import envConfig from 'configuration/env';
import { ProductFlavours } from 'constants/enums';

const { id: flavour } = envConfig;

const DEFAULT = ProductFlavours.Belong;

const asEnum = (product: string): ProductFlavours => {
  if (!product) {
    return DEFAULT;
  }

  if (product === 'youkno') {
    return ProductFlavours.Backstage;
  }

  return Object.values(ProductFlavours).includes(product as ProductFlavours) ? product as ProductFlavours : DEFAULT;
};

export const getCurrent = (): ProductFlavours => asEnum(flavour);

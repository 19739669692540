import { ToastContainer } from 'react-toastify';

import { ToastWrapper } from 'components/NotificationContainer/index.styled';

import useStoryDimensions from 'hooks/useStoryDimensions';

export const AUTO_CLOSE_TOAST = 4000;

export const NotificationContainer = () => {
  const { width } = useStoryDimensions();
  return (
    <ToastWrapper width={width}>
      <ToastContainer
        theme="colored"
        position="top-center"
        autoClose={AUTO_CLOSE_TOAST}
        pauseOnHover
        closeOnClick
        hideProgressBar
        newestOnTop
        stacked
      />
    </ToastWrapper>
  );
};

export default NotificationContainer;

import {
  FC,
  ReactNode,
  createContext,
  useState,
  useMemo,
  useContext,
} from 'react';

import { FbUserContext, FbUserDispatchContext } from 'context/FbUserContext';

interface LeaderboardContextProps {
  counter: number;
}

const LeaderboardContext = createContext<LeaderboardContextProps>({
  counter: 0,
});

interface LeaderboardDispatchContextProps {
  startCheck: () => void;
  stopCheck: () => void;
}

const LeaderboardDispatchContext = createContext<LeaderboardDispatchContextProps>({
  startCheck: () => null,
  stopCheck: () => null,
});

interface LeaderboardProviderProps {
  children: ReactNode;
}

let interval:any|null = null;

export const initialClaimTokensTimer = 60 * 9;

const LeaderboardProvider: FC<LeaderboardProviderProps> = ({ children }) => {
  const { onLogout } = useContext(FbUserDispatchContext);
  const { fbUser: loggedFbUser } = useContext(FbUserContext);
  const [counter, setCounter] = useState<number>(initialClaimTokensTimer);

  const handleTimer = () => {
    setCounter((prevState) => {
      if (prevState === 0) {
        stopCheck(true);
      }

      return prevState > 0 ? prevState - 1 : 0;
    });
  };

  const startCheck = () => {
    if (!interval) {
      interval = setInterval(handleTimer, 1000);
    }
  };

  const stopCheck = (triggerLogout?: boolean) => {
    setCounter(initialClaimTokensTimer);
    clearInterval(interval);
    interval = null;

    if (triggerLogout && loggedFbUser?.isAnonymous) {
      onLogout();
    }
  };

  const store = useMemo(() => ({
    counter,
  }), [counter]);

  const dispatchContextStore = useMemo(() => ({
    startCheck,
    stopCheck,
  }), [loggedFbUser?.uid]);

  return (
    <LeaderboardContext.Provider value={store}>
      <LeaderboardDispatchContext.Provider value={dispatchContextStore}>
        {children}
      </LeaderboardDispatchContext.Provider>
    </LeaderboardContext.Provider>
  );
};

export {
  LeaderboardProvider,
  LeaderboardContext,
  LeaderboardDispatchContext,
};

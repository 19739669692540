/* eslint max-len: 0 */
import { ReactElement } from 'react';

import { CustomIcon } from 'models/icon.interface';

const Category = ({ color = 'current', size = 24 }: CustomIcon): ReactElement => (
  <svg id="category_black_24dp_1_" data-name="category_black_24dp (1)" xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox={`0 0 ${size} ${size}`}>
    <path id="Path_96" data-name="Path 96" d="M0,0H24V24H0Z" fill="none" />
    <path id="Path_97" data-name="Path 97" d="M11.15,3.4,7.43,9.48A1,1,0,0,0,8.28,11h7.43a1,1,0,0,0,.85-1.52L12.85,3.4a.993.993,0,0,0-1.7,0Z" fill={color} />
    <circle id="Ellipse_9" data-name="Ellipse 9" cx="4.5" cy="4.5" r="4.5" transform="translate(13 13)" fill={color} />
    <path id="Path_98" data-name="Path 98" d="M4,21.5h6a1,1,0,0,0,1-1v-6a1,1,0,0,0-1-1H4a1,1,0,0,0-1,1v6A1,1,0,0,0,4,21.5Z" fill={color} />
  </svg>
);

export default Category;

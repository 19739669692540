import { createTheme } from '@mui/material/styles';

import palette from 'assets/theme/palette';
import { variants } from 'assets/theme/variants';

const appTheme = createTheme({
  typography: {
    fontFamily: 'Nunito Sans, sans-serif',
    title1: variants.title1,
    h1: variants.h1,
    h2: variants.h2,
    h3: undefined,
    h4: undefined,
    h5: undefined,
    h6: undefined,
    subtitle: variants.subtitle,
    subtitle1: variants.subtitle1,
    subtitle2: variants.subtitle2,
    body1: variants.body1,
    body2: variants.body2,
    body3: variants.body3,
    caption: variants.caption,
    button: {
      textTransform: 'none',
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: ({ theme: muiTheme }: any) => ({
          borderRadius: '8px',
          boxShadow: 'none',
          fontWeight: '700',
          fontSize: '15px',
          marginTop: 0,

          '&:hover': {
            boxShadow: 'none',
          },

          '&.big': {
            fontSize: '20px',
          },

          '&.MuiButton-outlinedPrimary': {
            borderColor: muiTheme.palette.primary.main
          }
        }),
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: ({ theme: muiTheme }: any) => ({
          borderRadius: '8px',
          backgroundColor: muiTheme.palette.general.darkGrey,

          '&:hover:not(.Mui-disabled, .Mui-error) .MuiOutlinedInput-notchedOutline': {
            borderColor: muiTheme.palette.general.borderColor,
          },
        }),
      },
    },
    MuiDialog: {
      styleOverrides: {
        paper: ({ theme: muiTheme }: any) => ({
          padding: muiTheme.spacing(3),

          [muiTheme.breakpoints.down('md')]: {
            padding: muiTheme.spacing(3, 2),
          },
        }),
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: () => ({
          minHeight: 'auto',
        }),
      },
    },
  },
  palette,
});

export default appTheme;

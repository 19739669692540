import { remove, set } from 'firebase/database';

import { runGetApiRequest, runPostApiRequest, runUpdateApiRequest } from 'services/Api';
import { userSocialActionStateRef } from 'services/FirebaseRefs';

import { EVENTS_ROUTE, UPDATE_USER_INFO_API, USER_CONFIRMATION_API, USER_PROFILE_API } from 'constants/apiRoutes';
import { ConfirmationType } from 'constants/enums';
import { QueueActions } from 'constants/queueActions';
import { UserSocialData } from 'models/user.interface';
import { formatRoute } from 'utils/formatters';

export const updateUserInfo = async (userId: string, user: any) => {
  const endpoint = formatRoute(UPDATE_USER_INFO_API, { userId });
  return runUpdateApiRequest(endpoint, user);
};

export const followUser = async (userId: string, follow: boolean) => {
  const endpoint = formatRoute(EVENTS_ROUTE, { action: QueueActions.FollowUser });
  return runPostApiRequest(endpoint, { userId, follow });
};

export const updateSocialActionState = async (
  loggedUserId: string,
  userId: string,
  socialActionType: string,
  userSocial: UserSocialData|null,
) => {
  const ref = userSocialActionStateRef(loggedUserId, userId, socialActionType);

  if (userSocial) {
    return set(ref, userSocial);
  }

  return remove(ref);
};

export const getUserProfile = async (userId: string) => {
  const endpoint = formatRoute(USER_PROFILE_API, { userId });
  return runGetApiRequest(endpoint);
};

export const updateUserHeadsUpTimerConfirmation = async (userId: string) => {
  const endpoint = formatRoute(USER_CONFIRMATION_API, { userId });
  const confirmation = {
    type: ConfirmationType.HideLetsGoDialog,
    confirmed: true,
    confirmedAt: Date.now(),
  };
  return runPostApiRequest(endpoint, confirmation);
};

import axios from 'axios';

export const runGetApiRequest = async (endpoint: string, params: any = {}) => (
  axios.get(
    endpoint,
    { params },
  )
);

export const runPostApiRequest = async (endpoint: string, data?: any, params: any = {}) => (
  axios.post(
    endpoint,
    data,
    { params },
  )
);

export const runUpdateApiRequest = async (endpoint: string, data: any, params: any = {}) => (
  axios.put(
    endpoint,
    data,
    { params },
  )
);

export const runPatchApiRequest = async (endpoint: string, data: any, params: any = {}) => (
  axios.patch(
    endpoint,
    data,
    { params },
  )
);

export const runDeleteApiRequest = async (endpoint: string, params: any = {}) => (
  axios.delete(
    endpoint,
    { params },
  )
);

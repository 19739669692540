import { styled } from '@mui/material/styles';

import { xContentPadding } from 'assets/theme/customSpacing';

export const ToastWrapper = styled('div')<{ width: number }>(({ theme, width }) => ({
  '& .Toastify__toast-container--top-center': {
    width: `calc(${width}px - (${2 * xContentPadding}px))`,
    top: '7px',

    '@media only screen and (max-width: 480px)': {
      left: `calc((100vw - ${width}px) / 2 + ${xContentPadding}px)`,
    },

    '@media only screen and (max-width: 400px)': {
      left: `${xContentPadding}px`,
    },
  },

  '& .Toastify__toast-container': {
    padding: 0,
  },

  '& .Toastify__toast': {
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
    borderTopLeftRadius: '8px',
    borderTopRightRadius: '8px',
  },

  '& .Toastify__toast-icon': {
    display: 'none',
  },

  '& .Toastify__close-button': {
    display: 'none',
  },

  '& .Toastify__toast-body': {
    textAlign: 'center',
  },

  '& .Toastify__toast-theme--colored.Toastify__toast--warning': {
    background: theme.palette.warning.main,
    color: theme.palette.common.black,
  },
}));
